export const lightTheme = {
    background: '#E6EBE0',
    backgroundStart: '#E6EBE0',
    text: '#5D576B',
    textStart: '#5D576B',
    headerUnderline: '#ED6A5A',
  
    navbar: '#5D576B',
    navbarStart: '#E6EBE0',
  
    socialStart: '#5D576B',
    socialStartHover: '#ED6A5A',
  
    logo: '#5D576B',
    logoScroll: '#E6EBE0',
    logoSpan: '#5D576B',
    logoSpanScroll: '#E6EBE0',
  
    menuLink: '#E6EBE0',
    menuLinkScroll: '#5D576B',
    menuLinkActive: '#ED6A5A',
    menuLinkUnderline: '#ED6A5A',
  
    sunMoon: '#F4F1BB',
    sunMoonScroll: '#5D576B',
    sunMoonHover: '#ED6A5A',
  
    hamburger: '#5D576B',
    hamburgerStart: '#E6EBE0',
    hamburgerHover: '#ED6A5A',
  
    card: '#FFF',
    cardButton: '#FFF',
    cardText: '#5D576B',
    cardButtonBorder: '#5D576B',
    cardButtonText: '#5D576B',
    cardButtonHover: '#ED6A5A',
    cardButtonBorderHover: '#ED6A5A',
    cardButtonTextHover: '#FFF',
  
    footer: '#5D576B',
    footerStart: '#5D576B',
  };
  
  export const darkTheme = {
    background: '#111825',
    backgroundStart: '#111825',
    text: '#E6EBE0',
    textStart: '#E6EBE0',
    headerUnderline: '#ED6A5A',
  
    navbar: '#111825',
    navbarStart: '#111825',
  
    socialStart: '#E6EBE0',
    socialStartHover: '#ED6A5A',
  
    logo: '#E6EBE0',
    logoScroll: '#5D576B',
    logoSpan: '#E6EBE0',
    logoSpanScroll: '#5D576B',
  
    menuLink: '#5D576B',
    menuLinkScroll: '#E6EBE0',
    menuLinkActive: '#ED6A5A',
    menuLinkUnderline: '#ED6A5A',
  
    sunMoon: '#5D576B',
    sunMoonScroll: '#F4F1BB',
    sunMoonHover: '#ED6A5A',
  
    hamburger: '#E6EBE0',
    hamburgerStart: '#5D576B',
    hamburgerHover: '#ED6A5A',
  
    card: '#f6f7f8',
    cardButton: '#f6f7f8',
    cardText: '#5D576B',
    cardButtonBorder: '#5D576B',
    cardButtonText: '#5D576B',
    cardButtonHover: '#ED6A5A',
    cardButtonBorderHover: '#ED6A5A',
    cardButtonTextHover: '#f6f7f8',
  
    footer: '#E6EBE0',
    footerStart: '#E6EBE0',
  };
  
